<script setup lang="ts">
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/vue'
import type { JobItem } from '~/models/Job'

interface Props {
  datas: any
}

const props = defineProps<Props>()
const isLoading = ref(true)
const isError = ref(false)
const jobList = ref()
const loadMore = ref(false)
const selectedTab = ref(props.datas?.tab_default)
const searchConfig = useSearchApiConfig()

// INITIAL FETCH
jobList.value = props.datas?.tab_options.map((item: any) => ({ ...item, haveFetch: false, isLoading: false, job: [] }))
try {
  jobList.value[0].isLoading = true
  const [result] = await Promise.all([
    useAsyncData('get_first', () => getJobBase(jobList.value[0].value), { server: true }),
  ])

  const raw = result.data.value as { hits: JobItem[] }
  if (!isEmpty(raw)) {
    jobList.value[0].haveFetch = true
    jobList.value[0].job = raw?.hits
  }
  jobList.value[0].isLoading = false
}
catch (error) {
  console.error('Error fetching data', error)
  showError({ message: 'Failed to load data', statusCode: 500 })
}

async function tabChange(idx: number, value: string) {
  loadMore.value = false
  selectedTab.value = value

  if (!jobList.value[idx].haveFetch) {
    jobList.value[idx].isLoading = true
    const result = await getJobBase(jobList.value[idx].value) as { hits: JobItem[] }
    if (!isEmpty(result)) {
      jobList.value[idx].haveFetch = true
      jobList.value[idx].job = result?.hits
    }
    jobList.value[idx].isLoading = false
  }
}

async function getJobBase(keyword: string): Promise<{}> {
  try {
    const response = await searchConfig('/jobs/search', {
      method: 'POST',
      body: {
        country: useGetSubdomain(),
        city: '',
        types: ['full_time', 'part_time', 'freelance', 'internship'],
        remote: '',
        term: keyword,
        from: 0,
        size: 6,
        sort: 'date',
        type: 'simple_title_with_similar_titles_search',
      },
    })

    return response
  }
  catch (error) {
    isError.value = true
  }
}
</script>

<template>
  <BaseAtomsSkeletonBounded v-if="!isEmpty(jobList) && !isError && !jobList.every(item => item.job.length === 0)" :class="`${props.datas?.max_width ? props.datas?.max_width : 'px-5 !lg:p-0'}`" class="text-gray-900 grid" container="full">
    <h2 class="text-2xl lg:text-4xl font-bold text-center mb-6">
      {{ props.datas?.title }}
    </h2>

    <TabGroup>
      <TabList class="flex space-x-2 mx-auto" style="width: fit-content;">
        <Tab v-for="(item, idx) in jobList" :key="idx" v-slot="{ selected }" as="template" @click="tabChange(idx, item.value)">
          <button class="px-2 pb-2" :class="[selected ? 'font-bold border-b-2 border-[#5843E5] focus:ring-0 focus:outline-none text-[#212121]' : 'text-[#666666]']">
            {{ item.label }}
          </button>
        </Tab>
      </TabList>

      <TabPanels class="mt-6">
        <TabPanel v-for="(item, idx) in jobList" :key="idx" class="focus:ring-0 focus:ring-offset-0 grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 md:gap-4 lg:gap-6">
          <template v-if="!item.isLoading && item.haveFetch">
            <BrowsejobsJobCard v-for="(data, idxData) in !item.haveFetch ? 6 : item.job" :key="idxData" :send-impression="true" impression-source="featured" :is-loading="item.isLoading || !item.haveFetch" :item="data" :job-id="data.job_id" class="hidden md:block" />
            <BrowsejobsJobCard v-for="(data, idxData) in (!item.haveFetch ? 3 : (!isEmpty(item.job) ? item.job.slice(0, loadMore ? 6 : 3) : 3))" :key="idxData" :send-impression="true" impression-source="featured" :is-loading="item.isLoading || !item.haveFetch" :item="data" :job-id="data.job_id" class="block md:hidden" />
          </template>
        </TabPanel>
      </TabPanels>
    </TabGroup>

    <div v-if="!jobList[jobList.findIndex(obj => obj.value === selectedTab)].isLoading" class="mt-6 lg:mt-10 <md:grid grid-cols-1 flex">
      <div v-if="!loadMore" class="flex items-center text-sm font-bold text-gray-600 mb-4 mx-auto cursor-pointer md:hidden" @click="loadMore = true">
        <div class="flex flex-shrink-0">
          <Icon name="material-symbols:refresh" class="w-5 h-5 mr-2" />
        </div>
        Load More
      </div>
      <BaseAtomsButton :to="`${props.datas?.button_url}&q=${selectedTab}`" outline size="sm" class="text-sm font-bold border-[#5843E5] <md:w-full md:px-20 mx-auto">
        {{ props.datas?.button_label.replace('job_keyword', jobList.find((item: any) => item.value === selectedTab)?.label) }}
      </BaseAtomsButton>
    </div>
  </BaseAtomsSkeletonBounded>
</template>
